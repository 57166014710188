db-dropdown-with-search {
  .p-dropdown {
    width: 100%;
  }
}

.custom-chip {
  position: relative;
  margin-right: 8px;

  .p-chip.chip-db-purple {
    background: #7646ff;
    color: #f5f6fd;
  }

  .p-chip:not(.chip-db-purple) {
    background: #f5f6fd;
    color: #565656;
  }

  &.custom-chip-normal {
    height: 2rem;
    .p-chip {
      height: 2rem;
    }
    img,
    .p-avatar {
      height: 2rem;
      width: 2rem;
    }
  }

  &.custom-chip-large {
    .p-chip {
      height: 3rem;
    }
    img,
    .p-avatar {
      height: 3rem;
      width: 3rem;
    }
  }

  p-badge {
    position: relative;
    right: -32px;
    top: -20px;
  }
  .p-chip {
    .pi-chip-remove-icon {
      padding-left: 14px;
    }

    &.medium-gap {
      .pi-chip-remove-icon {
        @apply pl-m;
        @apply ml-0;
      }
    }
  }
  .chip-badge .p-badge {
    background-color: black;
  }
  p-avatar {
    margin-left: -0.8rem;
    margin-right: 8px;
  }
}

.no-shadow-card .p-card {
  box-shadow: none;
}

.p-card .p-card-content {
  padding: 0;
}

p-card.no-padding-card {
  .p-card-body {
    padding: 0;
  }
}

.field-checkbox,
.field-radiobutton {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.p-dialog-mask {
  z-index: 900;
}

.paywall-modal {
  .p-dialog-content {
    padding: 4px;
    border-radius: 0 0 8px 8px !important;

    .popup-container {
      max-width: unset;
      border-radius: 0;
      box-shadow: none;
      text-align: center;
    }
  }
  .p-dialog-header {
    span {
      font-size: 30px;
    }
  }
}

div.p-dialog .p-dialog-content {
  padding: 0;
  border-radius: 8px;
}

.ui-modal-card .p-card .p-card-body {
  padding: 42px 44px;
}

db-page-frame {
  .main-loader .p-progressbar {
    width: calc(100% + 40px);
    margin-left: -40px;
  }
}

.full-height-card {
  .p-card {
    height: 100%;
    .p-card-body {
      height: 100%;
      .p-card-content {
        height: 100%;
      }
    }
  }
}

.input-with-grey-bg .p-inputwrapper {
  &:not(.ng-invalid.ng-touched) {
    input {
      border-color: transparent;
    }
  }
  input {
    background: #f5f6fd;
  }
}

.input-text-centered input {
  text-align: center;
}

.p-button.block {
  display: block;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0;
}

/* Customize prime tooltip */
.p-tooltip {
  max-width: 16rem !important;
  border-radius: 8px;

  /*Small text*/
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: $text-gray;
}

.ui-chart .ui-chart-tooltip {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  transform: translate(-50%, -100%);
  pointer-events: none;
}

button.no-horizontal-padding {
  padding-left: 0;
  padding-right: 0;
}

.not-colored-status-button:hover {
  background: $button-hover-clr !important;
}

/* Override pointer-events so that we can show tooltips over disabled class and use p-disabled at the same time */
.p-disabled.p-disabled-with-pointer-events {
  pointer-events: visible !important;
}

/* Please use overrides folder with file per each component instead of this file */
